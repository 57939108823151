import React, { useState, useEffect } from 'react'
import {
  Button,
  VoceMenuSecondoLivelloDesktop,
  VoceMenuTerzoLivelloDesktop,
} from 'react-components/atoms'
import { VoceMenuTile, VoceMenuSecondoLivelloMobile } from 'react-components/molecules'
import { api, CAS_DOMAIN } from 'utils'
import { Image } from 'react-components/atoms'
import Link from 'next/link'
import { NUMERIRIVISTE } from 'utils/endpoint'
import { ArrowRightLong } from 'react-components/atoms/Icons'

const SubMenu = (props) => {
  const {
    vocePrimoLivello,
    handleGTM = () => {},
    onMouseLeave = () => {},
    open = false,
    mobile = false,
  } = props

  if (!vocePrimoLivello?.children?.length) return null

  const [ultimiNumeri, setUltimiNumeri] = useState([])

  const withoutChildren = vocePrimoLivello.children.filter((child) => !child.children.length)
  const withChildren = vocePrimoLivello.children.filter(
    (child) => child.key !== 'horizontal' && child.children.length
  )
  const horizontalBox = vocePrimoLivello.children.find(
    (child) => child.key === 'horizontal' && child.children.length
  )

  const isRiviste = vocePrimoLivello.key === 'riviste'
  const isFormazione = vocePrimoLivello.key === 'eventi-e-formazione'
  const isShop = vocePrimoLivello.key === 'shop'
  const isLibri = vocePrimoLivello.key === 'catalogo'

  const horizontalFlow = withoutChildren.length < 2
  const simpleFlow =
    (horizontalFlow && withChildren.length < 2) || (!withChildren.length && withoutChildren.length)
  const containerFlowClasses = isRiviste
    ? 'submenu__container--riviste-flow'
    : simpleFlow
    ? 'submenu__container--simple-flow'
    : ''

  const baseListClass = 'submenu__list'
  const listFlowClasses = isShop
    ? `${baseListClass}--shop-flow`
    : horizontalFlow
    ? `${baseListClass}--horizontal-flow`
    : ''
  const fourChildrenFlowClasses = 'submenu__container--fourchildren-flow'
  useEffect(() => {
    if (isRiviste && !ultimiNumeri.length) initUltimiNumeri()
  }, [isRiviste])

  async function initUltimiNumeri() {
    const cached = sessionStorage.getItem('ultimi-numeri-riviste-v2') || ''

    if (cached) {
      setUltimiNumeri(JSON.parse(cached))
    } else {
      const params = { rivista_id: 2 } // ID vita scolastica
      const response = await api.get(`${NUMERIRIVISTE}latest/`, { params })
      const numero = response.data
      const lista = [numero]
      sessionStorage.setItem('ultimi-numeri-riviste-v2', JSON.stringify(lista))
      setUltimiNumeri(lista)
    }
  }

  return (
    <div className={`submenu ${open ? 'submenu--open' : ''}`} onMouseLeave={onMouseLeave}>
      {mobile && <VoceMenuSecondoLivelloDesktop label={vocePrimoLivello.name} onClick={() => {}} />}
      <div className={`submenu__container ${containerFlowClasses}`}>
        {withChildren.length ? (
          <div
            className={`submenu__list ${
              withChildren.length === 4 ? fourChildrenFlowClasses : listFlowClasses
            } ${isLibri ? 'submenu__list--libri' : ''}`}
          >
            {withChildren.map((item, k) => (
              <div
                className={`submenu__list__item ${isFormazione ? 'formazione-viva' : ''}`}
                key={k}
              >
                {mobile ? (
                  <VoceMenuSecondoLivelloMobile
                    label={item.name}
                    url={item.final_url}
                    onClick={() => {
                      handleGTM(vocePrimoLivello.name, item.name, null)
                    }}
                    blank={item.blank}
                  >
                    <div className="submenu__list__item__leaves submenu__list__item__leaves--mobile">
                      {item.children.map((leaf, index) => (
                        <VoceMenuTerzoLivelloDesktop
                          className={
                            item.final_url === leaf.final_url ? 'terzo-desktop--see-all' : ''
                          }
                          label={leaf.name}
                          url={leaf.final_url}
                          onClick={() => {
                            handleGTM(vocePrimoLivello.name, item.name, leaf.name)
                          }}
                          blank={leaf.blank}
                          key={index}
                        />
                      ))}
                    </div>
                  </VoceMenuSecondoLivelloMobile>
                ) : (
                  <>
                    <VoceMenuSecondoLivelloDesktop
                      label={item.name}
                      url={item.final_url}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleGTM(vocePrimoLivello.name, item.name, null)
                      }}
                      blank={item.blank}
                    />
                    <div className="submenu__list__item__leaves">
                      {/* Aggiunto split del menu solamente per formazione-viva e nel caso ci siano più di 4 figli, come richiesto da evolutiva */}
                      {item.key === 'formazione-viva' && item.children.length > 4 ? (
                        <div className="submenu__list__item__leaves__formazione-viva">
                          <div>
                            {item.children
                              .slice(0, Math.ceil(item.children.length / 2))
                              .map((leaf, index) => (
                                <VoceMenuTerzoLivelloDesktop
                                  className={
                                    item.final_url === leaf.final_url
                                      ? 'terzo-desktop--see-all'
                                      : ''
                                  }
                                  label={leaf.name}
                                  url={leaf.final_url}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleGTM(vocePrimoLivello.name, item.name, leaf.name)
                                  }}
                                  blank={leaf.blank}
                                  key={index}
                                />
                              ))}
                          </div>
                          <div>
                            {item.children
                              .slice(Math.ceil(item.children.length / 2))
                              .map((leaf, index) => (
                                <VoceMenuTerzoLivelloDesktop
                                  className={
                                    item.final_url === leaf.final_url
                                      ? 'terzo-desktop--see-all'
                                      : ''
                                  }
                                  label={leaf.name}
                                  url={leaf.final_url}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleGTM(vocePrimoLivello.name, item.name, leaf.name)
                                  }}
                                  blank={leaf.blank}
                                  key={index}
                                />
                              ))}
                          </div>
                        </div>
                      ) : (
                        item.children.map((leaf, index) => (
                          <VoceMenuTerzoLivelloDesktop
                            className={
                              item.final_url === leaf.final_url ? 'terzo-desktop--see-all' : ''
                            }
                            label={leaf.name}
                            url={leaf.final_url}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleGTM(vocePrimoLivello.name, item.name, leaf.name)
                            }}
                            blank={leaf.blank}
                            key={index}
                          />
                        ))
                      )}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ) : null}
        {isRiviste ? (
          <>
            <div className="submenu__tiles submenu__tiles--riviste">
              {withoutChildren.map((item, k) => (
                <Link legacyBehavior href={item.final_url} key={k}>
                  <a
                    className="submenu__tiles__rivista"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleGTM(vocePrimoLivello.name, item.name, null)
                    }}
                  >
                    <Image
                      src={CAS_DOMAIN + item.image}
                      className="submenu__tiles__rivista__image"
                      width={140}
                      height={40}
                      alt={item.name?.toAltTitle()}
                      title={item.name?.toAltTitle()}
                    />
                  </a>
                </Link>
              ))}
            </div>
            {ultimiNumeri.length ? (
              <div className="submenu__tiles submenu__tiles--numeri-riviste">
                {ultimiNumeri.map((item, k) => (
                  <div className="submenu__tiles__numero-rivista" key={k}>
                    <img
                      src={CAS_DOMAIN + item.main_image}
                      className="submenu__tiles__numero-rivista__image"
                      width={120}
                      height={160}
                      alt={item.titolo?.toAltTitle()}
                      title={item.titolo?.toAltTitle()}
                    />
                    <div className="submenu__tiles__numero-rivista__content">
                      <p className="submenu__tiles__numero-rivista__title">
                        {item.rivista?.titolo?.split('|')[0]}
                      </p>
                      <p className="submenu__tiles__numero-rivista__description">
                        n. {item.numero} {item.mese} {item.anno_solare}
                      </p>
                      <Button
                        className="submenu__tiles__numero-rivista__cta"
                        label={'Leggi ora'}
                        variant="ghost"
                        href={item.url}
                        icon={<ArrowRightLong />}
                        iconPos="right"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleGTM(vocePrimoLivello.name, item.titolo, null)
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </>
        ) : withoutChildren.length ? (
          withChildren.length ? (
            <div className="submenu__tiles">
              {withoutChildren.map((item, k) => (
                <VoceMenuTile
                  item={item}
                  className={item.key ? `menu-tile--${item.key}` : ''}
                  imageInHeader={vocePrimoLivello.key === 'didattica'}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleGTM(vocePrimoLivello.name, item.name, null)
                  }}
                  variant={vocePrimoLivello.key}
                  key={k}
                />
              ))}
            </div>
          ) : (
            <>
              <div className="submenu__voci-libere submenu__voci-libere--text">
                {withoutChildren
                  .filter((item) => !item.image)
                  .map((item, k) => (
                    <VoceMenuTerzoLivelloDesktop
                      label={item.name}
                      url={item.final_url}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleGTM(vocePrimoLivello.name, item.name, null)
                      }}
                      blank={item.blank}
                      key={k}
                    />
                  ))}
              </div>
              <div className="submenu__voci-libere submenu__voci-libere--tiles">
                {withoutChildren
                  .filter((item) => item.image)
                  .map((item, k) => (
                    <Link legacyBehavior href={item.final_url} key={k}>
                      <a
                        className="submenu__tiles__libera"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleGTM(vocePrimoLivello.name, item.name, null)
                        }}
                      >
                        <Image
                          src={CAS_DOMAIN + item.image}
                          width={140}
                          height={77}
                          alt={item.name?.toAltTitle()}
                          title={item.name?.toAltTitle()}
                        />
                      </a>
                    </Link>
                  ))}
              </div>
            </>
          )
        ) : null}
        {horizontalBox ? (
          <div className="submenu__tiles submenu__tiles--riviste submenu__tiles--catalogo">
            {horizontalBox.children?.map((item, k) => (
              <Link legacyBehavior href={item.final_url} key={k}>
                <a
                  className="submenu__tiles__text"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleGTM(vocePrimoLivello.name, item.name, null)
                  }}
                >
                  {item.image && (
                    <Image
                      src={CAS_DOMAIN + item.image}
                      className="submenu__tiles__text__image"
                      width={60}
                      height={60}
                      alt={item.name?.toAltTitle()}
                      title={item.name?.toAltTitle()}
                    />
                  )}
                  <p className="submenu__tiles__text__text">{item.name}</p>
                </a>
              </Link>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default SubMenu
