export const TEMI = 'temi/'
export const DISCIPLINE = 'discipline/'
export const ARTICOLI = 'articoli/'
export const ARTICOLICORRELATI = 'articoli/correlati/'
export const STRUMENTI = 'strumenti/'
export const STRUMENTICORRELATI = 'strumenti/correlati/'
export const RACCOLTE = 'raccolte/'
export const RACCOLTECORRELATI = 'raccolte/correlati/'
export const AUTORI = 'autori/'
export const ELEMENTI = 'elementiraccolta/?raccolta='
export const ELEMENTIRACCOLTA = 'elementiraccolta/'
export const REDIRECTS = 'redirects/'
export const PAGINE = 'pagine/'
export const PAGINEDETAIL = 'pagine/getbypathorchiave/'

export const SLUG = 'slug/'

export const PAGEBUILDER = 'pagebuilder/'
export const TRADUZIONI = 'traduzioni/'
export const CONTENTTYPE = 'contenttype/'
export const REGISTRAZIONE = 'registrazione/'
export const SCUOLE = 'scuole/'
export const UTENTISCUOLE = 'utentiscuole/'
export const MENU = 'menu/'
export const UTENTE = 'utentecorrente/'
export const ELEMENTOSALVATO = 'elementisalvati/'
export const ELEMENTOSEGUITO = 'elementiseguiti/'
export const COMMENTI = 'commenti/'
export const FEEDBACK = 'feedbacks/'
export const RATING = 'ratings/'

export const LISTINGSEARCH = 'listing/search/'
export const LISTINGALGOLIA = 'listing/algolia/'
export const LISTINGSALVATI = 'listing/salvati/'
export const LISTINGDALLAREDAZIONE = 'listing/dalla_redazione/'

export const NUMERIRIVISTE = 'numeri-riviste/'
export const RIVISTE = 'riviste/'
export const ABBONAMENTI = 'abbonamenti/'
export const CATEGORIEFAQ = 'categoriefaq/'
export const PAGINEFAQ = 'paginefaq/'
export const AGENTI = 'agenti/'
export const CONTATTI = 'contatti/'
export const PUBBLICAARTICOLO = 'pubblica-articolo/'
export const CONTATTAESPERTO = 'contattaesperto/'
export const ELEMENTISBLOCCATI = 'elementisbloccati/sblocca/'
export const FORMAZIONECORSI = 'formazione/'
export const GETFILTERS = 'get_filters/'
export const GETFILTERID = 'get_filter_id/'
export const MODULOFORMAZIONE = 'moduloformazione/'
export const ISCRIZIONEMODULO = 'iscrizione_modulo/'
export const GETISCRIZIONEMODULO = 'get_iscrizione_modulo/'
export const GETMODULIUTENTE = 'get_moduli_utente/'

export const PARASCOLASTICO = 'parascolastico/'
export const LIBROINFANZIA = 'libroinfanzia/'
export const LIBROAUXILIA = 'libroauxilia/'
export const LIBRORAGAZZI = 'libroragazzi/'
export const CORSOADOZIONALE = 'corsoadozionale/'
export const LIBRO = 'libri/'
export const VOLUME = 'volumi/'
export const PRODOTTI_DROPSHIPPING = 'prodotti-dropshipping/'
export const SHOP = 'shop/'
export const GET_LISTING = 'get_listing/'

export const LISTADISPOSITIVI = 'dispositivi/get_devices/'
export const RIMUOVIDISPOSITIVO = 'dispositivi/remove_device/'
export const LISTAVALIDAZIONI = 'validadocente/'
export const LISTAVALIDAZIONEFILTER = 'validadocente/?nome_scuola='
export const OCCUPAZIONI = 'occupazioni/'

export const WIDGET = 'widget/'
export const AVATAR = 'avatar/'

export const VALORIATTRIBUTO = 'valori-attributo/'

export const POPUP = 'popup/'

export const CELLECALENDARIO = 'celle-calendario/'

export const CHECKCODICEFISCALE = 'codice-fiscale/check/'

export const CHECK_REVALIDATE = 'check-revalidate/'
