import React from 'react'
import { Button } from 'react-components/atoms'

const EmptyMessage = (props) => {
  const {
    icon = undefined,
    titolo = '',
    descrizione = '',
    ctaLabel = '',
    href = undefined,
    onClick = undefined,
    ctaLabel2 = '',
    href2 = undefined,
    onClick2 = undefined,
    ctaLabel3 = '',
    href3 = undefined,
    onClick3 = undefined,
    ctaLabel4 = '',
    href4 = undefined,
    onClick4 = undefined,
    ctaLabel5 = '',
    href5 = undefined,
    onClick5 = undefined,
    className = '',
  } = props

  return (
    <div className={`empty-message ${className ? ` ${className}` : ''}`}>
      {!!icon && <div className="empty-message__icon">{icon}</div>}
      {!!titolo && (
        <p className="empty-message__title" dangerouslySetInnerHTML={{ __html: titolo }} />
      )}
      {!!descrizione && (
        <p
          className="empty-message__description"
          dangerouslySetInnerHTML={{ __html: descrizione }}
        />
      )}
      {!!(ctaLabel && (href || onClick)) && (
        <div className="empty-message__cta-box">
          <Button
            label={ctaLabel}
            variant="secondary"
            href={href}
            onClick={onClick}
            className="empty-message__cta-box__first"
          />
          {ctaLabel2 && (href2 || onClick2) && (
            <Button
              label={ctaLabel2}
              variant="secondary"
              href={href2}
              onClick={onClick2}
              className="empty-message__cta-box__second"
            />
          )}
          {ctaLabel3 && (href3 || onClick3) && (
            <Button
              label={ctaLabel3}
              variant="secondary"
              href={href3}
              onClick={onClick3}
              className="empty-message__cta-box__third"
            />
          )}
          {ctaLabel4 && (href4 || onClick4) && (
            <Button
              label={ctaLabel4}
              variant="secondary"
              href={href4}
              onClick={onClick4}
              className="empty-message__cta-box__third"
            />
          )}
          {ctaLabel5 && (href5 || onClick5) && (
            <Button
              label={ctaLabel5}
              variant="secondary"
              href={href5}
              onClick={onClick5}
              className="empty-message__cta-box__third"
            />
          )}
        </div>
      )}
    </div>
  )
}

export default EmptyMessage
